import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import fileDownload from "js-file-download";
import store from "@/store";
import { xhr } from "@/utils/axios";
import { lang } from "@/assets/lang/de";
import { icons } from "@/variables";

const media = (key) => icons[key];

const translate = (str) => {
  return lang[str] ? lang[str] : str;
};

const dateOrDash = (dateStr) => {
  return dateStr ? moment(dateStr, "YYYY-MM-DD").format("DD.MM.YYYY") : "-";
};

const dateDiff = (date1, date2 = moment(), of = "days") => {
  const d1 = moment(date1);
  const d2 = moment(date2);

  return d1.diff(d2, of);
};

const formatWith = (str, formatter = "Y") => {
  return moment(str).format(formatter);
};

const onFileChange = (e) => {
  let file = e.target.files[0];
  const fileObj = { file: file };

  fileObj.url = URL.createObjectURL(file);

  return fileObj;
};

const timestamp = () => {
  return uuidv4();
};

const recursiveAppend = (node, value, payload) => {
  if (typeof node === "object") {
    for (let i = 0; i < node.length; i++) {
      let element = node[i];
      element.state_is_open = true;
      if (element.uuid === value) {
        element.roleprofiles.push(payload);
      } else {
        recursiveAppend(element.children, value, payload);
      }
    }
  } else {
    return;
  }

  return node;
};

const recursiveUpdate = (node) => {
  if (typeof node === "object") {
    for (let i = 0; i < node.length; i++) {
      node[i] = {
        ...node[i],
        is_disabled: true,
        state_is_open: true,
        _key: node[i].uuid,
      };

      recursiveUpdate(node[i].children);
    }
  } else {
    return;
  }

  return node;
};

const recursiveDelete = (node, value) => {
  if (typeof node === "object") {
    for (let i = 0; i < node.length; i++) {
      let element = node[i];
      if (element.uuid === value) {
        node.splice(i, 1);
        i--;
      } else {
        recursiveDelete(element.children, value);
      }
    }
  } else {
    return;
  }
};

const updateUUIDs = (units) => {
  _.each(units, (unit) => {
    if (!unit["uuid"].startsWith("p-")) {
      unit["uuid"] = `${unit["uuid"]}`;
    }

    unit["is_disabled"] = true;

    if (unit.children.length) updateUUIDs(unit.children);
  });

  return units;
};

const activeLink = (name) => {
  if (name === store.getters.currentRoute)
    return " border-blue-500 text-blue-500";
};

const badges = (arr, key) => {
  let output = "";
  _.each(arr, (a) => {
    output += `<span class="rounded px-2 py-1 mr-1 group-hover:bg-white group-hover:text-indigo-900 bg-indigo-900 text-white">${a[key]}</span>`;
  });

  return output;
};

const download = (data, filename) => {
  fileDownload(data, filename);
};

const can = (parent, child, module, permission, what) => {
  const { permissions } = store.getters.profile;

  const has = _.get(
    _.first(
      _.filter(_.first(permissions[parent])[child], (moduleObject) => {
        if (moduleObject.module === module) {
          return moduleObject;
        }
      })
    ),
    permission
  );

  return what.includes(has);
};

const downloadEvaluation = ({ obj, endpoint }) => {
  const name = obj.employe
    ? `auf ${obj.employe.personal.first_name} ${obj.employe.personal.last_name}`
    : `von ${obj.supervisor.personal.full_name}`;
  xhr
    .get(endpoint, {
      headers: {
        Accept: "application/json",
      },
      responseType: "blob",
    })
    .then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `Mitarbeitergespräch ${name} ${moment(
          obj.completed_at,
          "YYYY-MM-DD"
        ).format("DD.MM.YYYY")}.pdf`
      );

      document.body.appendChild(fileLink);

      fileLink.click();
    });
};

const statusTranslate = (status) => {
  const statuses = {
    open: "Offen",
    cancel: "Abbrechen",
    done: "Abgeschlossen",
    preparation: "Vorbereitung",
    preparation_done: "Vorbereitung Abgeschlossen",
  };

  return statuses[status];
};

const preparedBy = ({ prepared_by }) => {
  if (_.isArray(prepared_by)) {
    if (_.size(prepared_by) > 2) {
      if (_.has(prepared_by[0], "email")) {
        return [
          {
            ...prepared_by[0],
            personal: {
              name: prepared_by[0]?.email,
              personal_avatar: null,
              initial: prepared_by[0].email
                .substr(0, 2)
                .split("")
                .join(" "),
            },
          },
          {
            ...prepared_by[1],
            personal: {
              name: `${prepared_by[1]?.personal?.first_name} ${prepared_by[1]?.personal?.last_name}`,
              personal_avatar: prepared_by[1]?.personal?.personal_avatar
                ? prepared_by[1]?.personal?.personal_avatar
                : null,
              initial:
                prepared_by[1] &&
                prepared_by[1]?.personal &&
                prepared_by[1]?.personal.initial.split("").join(" "),
            },
          },
          {
            ...prepared_by[2],
            personal: {
              name: `${prepared_by[2]?.personal?.first_name} ${prepared_by[2]?.personal?.last_name}`,
              personal_avatar: prepared_by[2]?.personal?.personal_avatar
                ? prepared_by[2]?.personal?.personal_avatar
                : null,
              initial:
                prepared_by[2] &&
                prepared_by[2]?.personal &&
                prepared_by[2]?.personal.initial.split("").join(" "),
            },
          },
        ];
      } else if (_.has(prepared_by[1], "email")) {
        return [
          {
            ...prepared_by[0],
            personal: {
              name: `${prepared_by[0]?.personal?.first_name} ${prepared_by[0]?.personal?.last_name}`,
              personal_avatar: prepared_by[0]?.personal?.personal_avatar
                ? prepared_by[0]?.personal?.personal_avatar
                : null,
              initial:
                prepared_by[0] &&
                prepared_by[0]?.personal &&
                prepared_by[0]?.personal.initial.split("").join(" "),
            },
          },
          {
            ...prepared_by[1],
            personal: {
              name: prepared_by[1]?.email,
              personal_avatar: null,
              initial: prepared_by[1].email
                .substr(0, 2)
                .split("")
                .join(" "),
            },
          },
          {
            ...prepared_by[2],
            personal: {
              name: `${prepared_by[2]?.personal?.first_name} ${prepared_by[2]?.personal?.last_name}`,
              personal_avatar: prepared_by[2]?.personal?.personal_avatar
                ? prepared_by[2]?.personal?.personal_avatar
                : null,
              initial:
                prepared_by[2] &&
                prepared_by[2]?.personal &&
                prepared_by[2]?.personal.initial.split("").join(" "),
            },
          },
        ];
      } else if (_.has(prepared_by[2], "email")) {
        return [
          {
            ...prepared_by[0],
            personal: {
              name: `${prepared_by[0]?.personal?.first_name} ${prepared_by[0]?.personal?.last_name}`,
              personal_avatar: prepared_by[0]?.personal?.personal_avatar
                ? prepared_by[0]?.personal?.personal_avatar
                : null,
              initial:
                prepared_by[0] &&
                prepared_by[0]?.personal &&
                prepared_by[0]?.personal.initial.split("").join(" "),
            },
          },
          {
            ...prepared_by[1],
            personal: {
              name: `${prepared_by[1]?.personal?.first_name} ${prepared_by[1]?.personal?.last_name}`,
              personal_avatar: prepared_by[1]?.personal?.personal_avatar
                ? prepared_by[1]?.personal?.personal_avatar
                : null,
              initial:
                prepared_by[1] &&
                prepared_by[1]?.personal &&
                prepared_by[1]?.personal.initial.split("").join(" "),
            },
          },
          {
            ...prepared_by[2],
            personal: {
              name: prepared_by[2]?.email,
              personal_avatar: null,
              initial: prepared_by[2].email
                .substr(0, 2)
                .split("")
                .join(" "),
            },
          },
        ];
      }
    } else if (_.size(prepared_by) === 2) {
      if (_.has(prepared_by[0], "email")) {
        return [
          {
            ...prepared_by[0],
            personal: {
              name: prepared_by[0]?.email,
              personal_avatar: null,
              initial: prepared_by[0].email
                .substr(0, 2)
                .split("")
                .join(" "),
            },
          },
          {
            ...prepared_by[1],
            personal: {
              name: `${prepared_by[1]?.personal?.first_name} ${prepared_by[1]?.personal?.last_name}`,
              personal_avatar: prepared_by[1]?.personal?.personal_avatar
                ? prepared_by[1]?.personal?.personal_avatar
                : null,
              initial:
                prepared_by[1] &&
                prepared_by[1]?.personal &&
                prepared_by[1]?.personal.initial.split("").join(" "),
            },
          },
        ];
      } else if (_.has(prepared_by[1], "email")) {
        return [
          {
            ...prepared_by[0],
            personal: {
              name: `${prepared_by[0]?.personal?.first_name} ${prepared_by[0]?.personal?.last_name}`,
              personal_avatar: prepared_by[0]?.personal?.personal_avatar
                ? prepared_by[0]?.personal?.personal_avatar
                : null,
              initial:
                prepared_by[0] &&
                prepared_by[0]?.personal &&
                prepared_by[0]?.personal.initial.split("").join(" "),
            },
          },
          {
            ...prepared_by[1],
            personal: {
              name: prepared_by[1]?.email,
              personal_avatar: null,
              initial: prepared_by[1].email
                .substr(0, 2)
                .split("")
                .join(" "),
            },
          },
        ];
      } else {
        return [
          {
            ...prepared_by[0],
            personal: {
              name: `${prepared_by[0]?.personal?.first_name} ${prepared_by[0]?.personal?.last_name}`,
              personal_avatar: prepared_by[0]?.personal?.personal_avatar
                ? prepared_by[0]?.personal?.personal_avatar
                : null,
              initial:
                prepared_by[0] &&
                prepared_by[0]?.personal &&
                prepared_by[0]?.personal.initial.split("").join(" "),
            },
          },
          {
            ...prepared_by[1],
            personal: {
              name: `${prepared_by[1]?.personal?.first_name} ${prepared_by[1]?.personal?.last_name}`,
              personal_avatar: prepared_by[1]?.personal?.personal_avatar
                ? prepared_by[1]?.personal?.personal_avatar
                : null,
              initial:
                prepared_by[1] &&
                prepared_by[1]?.personal &&
                prepared_by[1]?.personal.initial.split("").join(" "),
            },
          },
        ];
      }
    } else {
      if (_.has(prepared_by[0], "email")) {
        return [
          {
            ...prepared_by[0],
            personal: {
              name: prepared_by[0]?.email,
              personal_avatar: null,
              initial: prepared_by[0].email
                .substr(0, 2)
                .split("")
                .join(" "),
            },
          },
        ];
      } else {
        return [
          {
            ...prepared_by[0],
            personal: {
              name: `${prepared_by[0]?.personal?.first_name} ${prepared_by[0]?.personal?.last_name}`,
              personal_avatar: prepared_by[0]?.personal?.personal_avatar
                ? prepared_by[0]?.personal?.personal_avatar
                : null,
              initial:
                prepared_by[0] &&
                prepared_by[0]?.personal &&
                prepared_by[0]?.personal.initial.split("").join(" "),
            },
          },
        ];
      }
    }
  }

  return prepared_by;
};

export {
  media,
  moment,
  translate,
  onFileChange,
  dateDiff,
  dateOrDash,
  formatWith,
  timestamp,
  recursiveAppend,
  recursiveUpdate,
  recursiveDelete,
  updateUUIDs,
  activeLink,
  badges,
  download,
  can,
  downloadEvaluation,
  statusTranslate,
  preparedBy,
};
