/* eslint-disable no-async-promise-executor */
import _ from "lodash"
import axios from "@/utils/axios"
import { xhr } from "@/utils/axios"

export const getAll = async () => {
  const response = await axios.get(`/organisation/unit/skill/`)

  return response
}

export const getAllData = async () => {
  const response = await xhr.get(`/organisation/unit/skill/data/`)

  return response
}

export const createOne = async (payload) => {
  const formData = new FormData()

  _.each(payload, (value, key) => {
    value && formData.append(key, value)
  })

  const response = await axios.post(`/organisation/unit/skill/`, formData)

  return response
}

export const updateOne = async (payload) => {
  const formData = new FormData()

  _.each(payload, (value, key) => {
    value && formData.append(key, value)
  })

  const response = await axios.patch(
    `/organisation/unit/skill/${payload.id}/`,
    formData
  )

  return response
}

export const deleteOne = async ({ id }) => {
  const response = await axios.delete(`/organisation/unit/skill/${id}/`)

  return response
}
