import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
// import axios from '@/utils/axios'
// import _ from 'lodash'

import { getAll } from '@/services/role'
import { getAll as getAllPersonal } from '@/services/personal'
import { getAllData as getAllSkill } from '@/services/skill'

Vue.use(Vuex)

const mstatus = {
  red: {
    style: 'bg-red-500',
    type: 'Warning',
    btn: {
      text: 'Rechnung bezahlen',
      title: 'Kundenportal',
      event: 'pay',
      component: 'strip-payment',
    },
  },
  yellow: { style: 'bg-yellow-500', type: 'Info', btn: null },
}

export default new Vuex.Store({
  plugins: [new VuexPersistence().plugin],
  state: {
    appName: process.env.VUE_APP_NAME,
    notification: {
      type: '',
      status: '',
      text: ``,
      show: false,
    },
    currentRoute: '/',
    query: null,
    filters: {},
    profile: {},
    loggedMeIn: false,
    loader: false,
    crums: null,
    reload: false,
    feedbackMessage: '',

    /////////////////////////

    currentNode: {},
    currentRole: {},
    roleProfileDeps: {},

    /////////////////////////

    review: {},
    dashboard: {},
    printing: false,
    modal: {},
    settings: {},
  },
  getters: {
    appName: state => state.appName,
    notification: state => state.notification,
    currentRoute: state => state.currentRoute,
    query: state => state.query,
    filters: state => state.filters,
    profile: state => state.profile,
    loggedMeIn: state => state.loggedMeIn,
    loader: state => state.loader,
    crums: state => state.crums,
    reload: state => state.reload,
    feedbackMessage: state => state.feedbackMessage,

    /////////////////////////

    currentNode: state => state.currentNode,
    currentRole: state => state.currentRole,
    roleProfileDeps: state => state.roleProfileDeps,

    /////////////////////////

    review: state => state.review,
    dashboard: state => state.dashboard,

    printing: state => state.printing,
    modal: state => state.modal,
    settings: state => state.settings,
  },
  mutations: {
    updateNotification(state, payload) {
      state.notification = payload
    },
    updateCurrentRoute(state, payload) {
      state.currentRoute = payload
    },
    updateQuery(state, payload) {
      state.query = payload
    },
    updateFilters(state, payload) {
      state.filters = payload
    },
    updateProfile(state, payload) {
      state.profile = payload
    },
    updateLoggedMeIn(state, payload) {
      state.loggedMeIn = payload
    },
    updateLoader(state, payload) {
      state.loader = payload
    },
    updateCrums(state, payload) {
      state.crums = payload
    },
    updateReload(state, payload) {
      state.reload = payload
    },
    updateFeedbackMessage(state, payload) {
      state.feedbackMessage = payload
    },

    /////////////////////////

    updateCurrentNode(state, payload) {
      state.currentNode = payload
    },
    updateCurrentRole(state, payload) {
      state.currentRole = payload
    },
    updateRoleProfileDeps(state, payload) {
      state.roleProfileDeps = payload
    },

    /////////////////////////

    updateReview(state, payload) {
      state.review = payload
    },
    updateDashboard(state, payload) {
      state.dashboard = payload
    },

    updatePrinting(state, payload) {
      state.printing = payload
    },

    setModal(state, payload) {
      state.modal = payload
    },
    setSettings(state, payload) {
      state.settings = payload
    },
  },
  actions: {
    updateNotification({ getters, commit }, payload) {
      commit('updateNotification', {
        ...payload,
        status: mstatus[payload.color],
      })

      if (getters.notification.color === 'red') {
        setTimeout(
          () =>
            commit('updateNotification', {
              ...payload,
              status: mstatus[payload.color],
              show: true,
            }),
          3000
        )
      }
    },
    updateCurrentRoute({ commit }, payload) {
      commit('updateCurrentRoute', payload)
    },
    updateQuery: ({ commit }, payload) => {
      commit('updateQuery', payload)
    },
    updateFilters: ({ commit }, payload) => {
      commit('updateFilters', payload)
    },
    updateProfile: ({ commit }, payload) => {
      commit('updateProfile', payload)
    },
    updateLoggedMeIn: ({ commit }, payload) => {
      commit('updateLoggedMeIn', payload)
    },
    updateLoader: ({ commit }, payload) => {
      commit('updateLoader', payload)
    },
    updateCrums: ({ commit }, payload) => {
      commit('updateCrums', payload)
    },
    updateReload: ({ commit }, payload) => {
      commit('updateReload', payload)
    },
    updateFeedbackMessage: ({ commit }, payload) => {
      commit('updateFeedbackMessage', payload)
    },

    /////////////////////////

    updateCurrentNode: ({ commit }, payload) => {
      commit('updateCurrentNode', payload)
    },
    updateCurrentRole: ({ commit }, payload) => {
      commit('updateCurrentRole', payload)
    },
    updateRoleProfileDeps: ({ commit, state }) => {
      commit('updateLoader', true)
      getAll(false)
        .then(response => {
          if (response.status === 200) {
            const responsibilities = response.data.results
            const deputies = response.data.results

            commit('updateRoleProfileDeps', {
              responsibilities,
              deputies,
            })
          }

          getAllPersonal(false, false).then(response => {
            if (response.status === 200) {
              const personals = response.data.results

              commit('updateRoleProfileDeps', {
                responsibilities: state.roleProfileDeps.responsibilities,
                deputies: state.roleProfileDeps.deputies,
                personals,
              })
            }

            getAllSkill().then(response => {
              if (response.status === 200) {
                const skills = response.data.results

                commit('updateRoleProfileDeps', {
                  responsibilities: state.roleProfileDeps.responsibilities,
                  deputies: state.roleProfileDeps.deputies,
                  personals: state.roleProfileDeps.personals,
                  skills,
                })
              }

              commit('updateLoader', false)
            })

            commit('updateLoader', false)
          })
        })
        .catch(() => {
          commit('updateLoader', false)
        })
    },

    /////////////////////////

    updateReview: ({ commit }, payload) => {
      commit('updateReview', payload)
    },

    updatePrinting: ({ commit }, payload) => {
      commit('updatePrinting', payload)
    },

    setModal({ commit }, payload) {
      commit('setModal', payload)
    },
    setSettings({ commit }, payload) {
      commit('setSettings', payload)
    },
  },
  modules: {},
})
