import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

const xhr = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

// Add a request interceptor
instance.interceptors.request.use(
  function(config) {
    try {
      const token = localStorage.getItem('access')
      store.dispatch('updateLoader', true)

      if (token) config.headers.Authorization = `Bearer ${token}`
    } catch (err) {
      console.log('Err ::: ', err)
    }

    return config
  },
  function(error) {
    store.dispatch('updateLoader', false)

    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  function(response) {
    store.dispatch('updateLoader', false)

    // Do something with response data
    response.data && response.data.message && Vue.$toast.success(response.data.message)

    return response
  },
  function(error) {
    store.dispatch('updateLoader', false)

    if(error.response.status == 500){
      return Promise.reject(error)
    }

    if (!error.response) {
      Vue.$toast.error('Network error!')
    } else {
      const response = error.response.data

      

      const message =
        response.message ||
        (response.non_field_errors && response.non_field_errors.join('<br />')) ||
        response.detail

      if (!error.response.config.url.startsWith('/authentication/')) Vue.$toast.error(message)

      return Promise.reject(error)
    }
  }
)

// Add a request interceptor
xhr.interceptors.request.use(
  function(config) {
    try {
      const token = localStorage.getItem('access')

      if (token) config.headers.Authorization = `Bearer ${token}`
    } catch (err) {
      console.log('Err ::: ', err)
    }

    return config
  },
  function(error) {
    // Do something with request error
    console.log('Error ::: ', error)
    return Promise.reject(error)
  }
)

// Add a response interceptor
xhr.interceptors.response.use(
  function(response) {
    response.data && response.data.message && Vue.$toast.success(response.data.message)

    return response
  },
  function(error) {
    if (!error.response) {
      alert('NETWORK ERROR')
    } else {
      // const code = error.response.status
      const response = error.response.data
      
      const message =
        response[0] || response.message || response.non_field_errors.join('<br />') || response.detail

      Vue.$toast.error(message)
      // const originalRequest = error.config;

      // if (code === 401 && !originalRequest._retry) {
      // originalRequest._retry = true

      // auth.commit('logout');
      // window.location.href = "/login";
      // }

      return Promise.reject(error)
    }
  }
)

// export const get = async url => {
//   const request = await instance.get(url)

//   request.then(response => {
//     if (response.stats === 200) {
//       return response.data
//     }
//   })
// }

export default instance

export { xhr }
