import Vue from 'vue'
import VueRouter from 'vue-router'
import { isLoggedIn } from '@/services/auth'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Auth/Login'),
    meta: {
      allowAnonymous: true,
      title: 'Login',
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('@/views/Auth/Forgot'),
    meta: {
      allowAnonymous: true,
      title: 'Forgot Password',
    },
  },
  {
    path: '/update-password',
    name: 'update-password',
    component: () => import('@/views/Auth/UpdatePassword'),
    meta: {
      allowAnonymous: true,
      title: 'Update Password',
    },
  },
  {
    path: '/user/invite/',
    name: 'confirm-invitation',
    component: () => import('@/views/Auth/ConfirmInvitation'),
    meta: {
      allowAnonymous: true,
      title: 'Invite User',
    },
  },
  {
    path: '/feedback/external/:token?',
    name: 'performance-evaluation-feedback',
    component: () => import('@/views/Employee/PerformanceReview/External'),
    meta: {
      allowAnonymous: true,
      title: 'External Feedback',
    },
  },
  {
    path: '/',
    component: () => import('@/views/Layouts/Main'),
    children: [
      // {
      //   path: '/',
      //   name: 'dashboard',
      //   component: () => import('@/views/Dashboard'),
      // },
      {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/Company/index'),
        meta: {
          admin: true,
          title: 'Mein Unternehmen',
        },
      },
      {
        path: '/personal',
        name: 'personal',
        component: () => import('@/views/Personal/List'),
        meta: {
          admin: false,
          title: 'Personals',
        },
      },
      {
        path: '/personal/new',
        name: 'personal-create',
        component: () => import('@/views/Personal/New'),
        meta: {
          admin: false,
          title: 'New Personal',
        },
      },


      {
        path: '/personal/my-performance-evaluation',
        name: 'my-performance-evaluation',
        component: () => import('@/views/Employee/PerformanceReview/MyList'),
        meta: {
          admin: false,
          title: 'Meine Mitarbeitergespräche',
        },
      },
      {
        path: '/personal/my-team-performance-evaluation',
        name: 'my-team-performance-evaluation',
        component: () => import('@/views/Employee/PerformanceReview/TeamList'),
        meta: {
          admin: false,
          title: 'Team Mitarbeitergespräche',
        },
      },
      {
        path: '/personal/performance-evaluation',
        name: 'all-performance-evaluation',
        component: () => import('@/views/Employee/PerformanceReview/AllList'),
        meta: {
          admin: false,
          title: 'Alle Mitarbeitergespräche',
        },
      },


      {
        path: '/personal/performance-evaluation/templates',
        name: 'personal-performance-evaluation-templates',
        component: () => import('@/views/Employee/PerformanceReview/Templates'),
        meta: {
          admin: false,
          title: 'Vorlage erstellen',
        },
      },
      {
        path: '/personal/performance-evaluation/prepare/:reviewId',
        name: 'personal-performance-evaluation-prepare',
        component: () => import('@/views/Employee/PerformanceReview/Prepare'),
        meta: {
          admin: false,
          title: 'Mitarbeitergespräch Vorbereitung',
        },
      },
      {
        path: '/personal/performance-evaluation/assesment/:reviewId',
        name: 'personal-performance-evaluation-assesment',
        component: () => import('@/views/Employee/PerformanceReview/Assesment'),
        meta: {
          admin: false,
          title: 'Abschliessende Beurteilung',
        },
      },
      {
        path: '/personal/:id',
        name: 'personal-details',
        component: () => import('@/views/Personal/View'),
        meta: {
          admin: false,
          title: 'Personal',
        },
      },
      {
        path: '/personal/:id/edit',
        name: 'personal-edit',
        component: () => import('@/views/Personal/Edit'),
        meta: {
          admin: false,
          title: 'Update Personal',
        },
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/Settings/Main'),
        meta: {
          admin: false,
          title: 'Allgemeine Einstellungen',
        },
      },
      {
        path: '/settings/update',
        name: 'settings-update',
        component: () => import('@/views/Settings/Edit'),
        meta: {
          admin: false,
          title: 'Firmenprofil',
        },
      },
      {
        path: '/settings/import-export',
        name: 'settings-import-export',
        component: () => import('@/views/Settings/ImportExport'),
        meta: {
          admin: false,
          title: 'Import / Export Personals',
        },
      },
      {
        path: '/settings/import',
        name: 'settings-import',
        component: () => import('@/views/Settings/Import'),
        meta: {
          admin: false,
          title: 'Import Personals',
        },
      },
      {
        path: '/settings/general',
        name: 'settings-general',
        component: () => import('@/views/Settings/General'),
        meta: {
          admin: false,
          title: 'Stammdaten',
        },
      },
      {
        path: '/settings/general/rating-scales',
        name: 'settings-general-rating-scales',
        component: () => import('@/views/Settings/RatingScale'),
        meta: {
          admin: false,
          title: 'Bewertungsskala definieren',
        },
      },
      {
        path: '/settings/general/pdf-settings',
        name: 'settings-general-pdf-settings',
        component: () => import('@/views/Settings/PdfSettings'),
        meta: {
          admin: false,
          title: 'PDF Einstellungen',
        },
      },
      {
        path: '/settings/general/notification-settings',
        name: 'settings-general-notification-settings',
        component: () => import('@/views/Settings/NotificationSettings'),
        meta: {
          admin: false,
          title: 'Notification Einstellungen',
        },
      },
      {
        path: '/settings/users-and-groups',
        name: 'users-and-groups',
        component: () => import('@/views/Settings/UsersAndGroups'),
        meta: {
          admin: false,
          title: 'Benutzer & Gruppenverwaltung',
        },
      },
      {
        path: '/settings/invite',
        name: 'invite-personal',
        component: () => import('@/views/Settings/InvitePersonal'),
        meta: {
          admin: false,
          title: 'Benutzer verwalten',
        },
      },
      {
        path: '/settings/groups-and-permissions',
        name: 'groups-and-permissions',
        component: () => import('@/views/Settings/GroupsAndPermissions'),
        meta: {
          admin: false,
          title: 'Gruppen verwalten',
        },
      },
      {
        path: '/my-company',
        name: 'my-company',
        component: () => import('@/views/Company/index'),
        meta: {
          admin: false,
          title: 'Company',
        },
      },
      {
        path: '/organisation',
        name: 'organisation',
        component: () => import('@/views/Organisation/index'),
        meta: {
          admin: false,
          title: 'Meine Organisationseinheit',
        },
        children: [
          {
            path: '/organisation/unit/:id',
            name: 'organisation-unit',
            component: () => import('@/views/Organisation/Unit'),
            meta: {
              title: 'Meine Organisationseinheit',
            },
          },
          {
            path: '/organisation/unit/roleprofile/:roleprofile',
            name: 'organisation-roleprofile',
            component: () => import('@/views/Organisation/RoleProfile'),
            meta: {
              title: 'Meine Organisationseinheit',
            },
          },
        ],
      },
      {
        path: '/account-suspended',
        name: 'account-suspended',
        component: () => import('@/views/Errors/AccountSuspended'),
        meta: {
          allowAnonymous: true,
          title: 'Account Suspended',
        },
      },
      {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/Auth/Logout'),
        meta: {
          allowAnonymous: true,
        },
      },
      {
        path: '/*',
        name: '404',
        component: () => import('@/views/Errors/Error'),
        meta: {
          title: 'Something went wrong',
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.API_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const { token_valid, message, uidb64, token, client } = to.query
  store.dispatch('updateCurrentRoute', to.name)
  document.title = `${store.getters.appName} - ${to.meta.title}`

  if (to.name === 'logout') store.dispatch('updateProfile', {})
  if (
    store &&
    store.state &&
    store.state.profile &&
    store.state.profile.org &&
    store.state.profile.org.plan_status &&
    store.state.profile.org.plan_status.redirect
  )
    window.location.href = store.state.profile.org.plan_status.redirect

  if (to.path === '/' && uidb64) {
    window.location.href = `/update-password?token_valid=${token_valid}&message=${message}&uidb64=${uidb64}&token=${token}&client=${client}`
  } else if (to.name == 'login' && isLoggedIn()) {
    next({ path: '/' })
  } else if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next(`/login?msg=${to.query.message || ''}`)
  } else if (to.meta.allowAnonymous) {
    next()
  } else if (
    store &&
    store.state &&
    store.state.profile &&
    store.state.profile.org &&
    store.state.profile.org.plan_status &&
    store.state.profile.org.plan_status.type === 'view' &&
    to.path !== '/account-suspended' &&
    from.path !== '/account-suspended'
  ) {
    next({ path: '/account-suspended' })
  } else {
    if (to.meta.admin) {
      store.state.profile.permissions.is_admin
        ? next()
        : next({ path: '/personal' })
    } else next()
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
