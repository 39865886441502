<template>
  <div
    class="relative z-10"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    v-show="modal.show"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    ></div>

    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
      >
        <div
          class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
        >
          <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              @click.prevent="setModal({ show: false })"
              type="button"
              class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="sr-only">Close</span>
              <!-- Heroicon name: outline/x-mark -->
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                {{ modal.title }}
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  {{ modal.message }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="modal.buttons"
            class="mt-5 sm:mt-4 sm:flex justify-end space-x-2 items-center"
          >
            <div class="text-sm">
              {{ modal.notice }}
            </div>
            <div
              v-for="(button, index) in modal.buttons"
              :key="`button-${index}`"
              class="flex items-center"
            >
              <button
                @click.prevent="onClick(button)"
                type="button"
                :class="button.style"
                class="w-full inline-flex justify-center rounded border shadow-sm px-3 py-1 focus:outline-none sm:w-auto sm:text-sm"
              >
                {{ button.label }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CGModal',
  props: {},
  data() {
    return {}
  },
  methods: {
    onClick(el) {
      el.callback()

      this.setModal({
        show: false,
        confirm: el.confirm,
      })
    },
  },
}
</script>

<style></style>
