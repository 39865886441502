<template>
  <div class="antialiased bg-gray-50">
    <CGModal />
    <loader />
    <transition>
      <router-view />
    </transition>

    <!-- <notification-box /> -->
  </div>
</template>

<script>
import moment from 'moment'
import CGModal from '@/components/Commons/CGModal'
import { logoutUser } from '@/services/auth'

import Loader from '@/components/Loader/Loader'

export default {
  name: 'app',
  props: [],
  components: { CGModal, Loader },
  data() {
    return {}
  },
  mounted() {
    this.updateLoader(false)
    const diff =
      moment().valueOf() - parseInt(localStorage.getItem('window_closed_at'))
    var applicationCount = localStorage.getItem('applicationCount')

    if (applicationCount <= 0) {
      if (!this.loggedMeIn && diff > 15000) {
        this.leaving()
        logoutUser()

        this.$router.push('/login')
      }
      window.addEventListener('beforeunload', this.leaving)
    }

    window.onload = function() {
      var applicationCount = localStorage.getItem('applicationCount') || 0
      localStorage.setItem('applicationCount', ++applicationCount)
    }

    window.onbeforeunload = function() {
      var applicationCount = localStorage.getItem('applicationCount') || 1

      localStorage.setItem('applicationCount', --applicationCount)
    }
  },
  methods: {
    leaving() {
      localStorage.setItem('window_closed_at', moment().valueOf())
    },

    async init() {
      const reopenWindow = localStorage.getItem('window_closed_at')
      const { loggedMeIn } = this.$store.getters

      if (!loggedMeIn && reopenWindow) {
        logoutUser()
        this.$router.push('/login')
      }
    },
  },
}
</script>

<style>
.mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}

input[type='text']:disabled {
  background-color: transparent;
}

.tooltip {
  max-width: 25%;
  margin: auto;
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar ::-webkit-scrollbar .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar ::-webkit-scrollbar input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar ::-webkit-scrollbar .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar ::-webkit-scrollbar ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar ::-webkit-scrollbar ::-webkit-scrollbar .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar ::-webkit-scrollbar ::-webkit-scrollbar .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar ::-webkit-scrollbar ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar ::-webkit-scrollbar ::-webkit-scrollbar-track .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar ::-webkit-scrollbar ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar ::-webkit-scrollbar ::-webkit-scrollbar-thumb .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar ::-webkit-scrollbar-track .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar ::-webkit-scrollbar-track input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar ::-webkit-scrollbar-track .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar ::-webkit-scrollbar-track ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar ::-webkit-scrollbar-track ::-webkit-scrollbar .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar ::-webkit-scrollbar-track ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar ::-webkit-scrollbar-track ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar ::-webkit-scrollbar-thumb .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar ::-webkit-scrollbar-thumb input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar ::-webkit-scrollbar-thumb .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar ::-webkit-scrollbar-thumb ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar ::-webkit-scrollbar-thumb ::-webkit-scrollbar .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar ::-webkit-scrollbar-thumb ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar ::-webkit-scrollbar-thumb ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}

::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar-track .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar-track ::-webkit-scrollbar .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track ::-webkit-scrollbar input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track ::-webkit-scrollbar .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track ::-webkit-scrollbar ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track ::-webkit-scrollbar ::-webkit-scrollbar .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-track ::-webkit-scrollbar ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-track ::-webkit-scrollbar ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-track ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar-track ::-webkit-scrollbar-track .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track ::-webkit-scrollbar-track .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track ::-webkit-scrollbar-track ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-track ::-webkit-scrollbar-track ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-track ::-webkit-scrollbar-track ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-track ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar-track ::-webkit-scrollbar-thumb .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track ::-webkit-scrollbar-thumb .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track ::-webkit-scrollbar-thumb ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-track ::-webkit-scrollbar-thumb ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-track ::-webkit-scrollbar-thumb ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar-thumb .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar ::-webkit-scrollbar .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar-track .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar-track .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar-track ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar-track ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar-track ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar-thumb .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar-thumb .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar-thumb ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar-thumb ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
::-webkit-scrollbar-thumb ::-webkit-scrollbar-thumb ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  input[type='text']:disabled {
  background-color: transparent;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  .tooltip {
  max-width: 25%;
  margin: auto;
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb
  ::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  border: none;
  font-size: 0.75rem;
  line-height: 0.5rem;
  padding: 0;
}
.style-chooser .vs__search,
.style-chooser .vs__selected,
.style-chooser .vs__search:focus {
  margin: 0;
}
.style-chooser .vs__actions {
  padding: 0;
}
.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}
</style>
