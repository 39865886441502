/* eslint-disable no-async-promise-executor */
// import _ from "lodash"
import decode from 'jwt-decode'
import axios from '@/utils/axios'

const AUTH_TOKEN_KEY = 'access'
const REFRESH_TOKEN_KEY = 'refresh'
const VUEX_PERSIST_KEY = 'vuex'

export const loginUser = async (email, password) => {
  const formData = new FormData()

  formData.append('email', email)
  formData.append('password', password)

  const response = await axios.post(`/authentication/login/`, formData)

  return response
}

export const logoutUser = () => {
  clearAuthTokens()
}

export const resetPassword = async email => {
  const formData = new FormData()

  formData.append('email', email)

  const response = await axios.post(`authentication/password-reset`, formData)

  return response
}

export const updatePassword = async (password, uidb64, token, client) => {
  const formData = new FormData()

  formData.append('password', password)
  formData.append('uidb64', uidb64)
  formData.append('token', token)
  formData.append('client', client)

  const response = await axios.patch(
    `authentication/password-reset-complete`,
    formData
  )

  return response
}

export const confirmInvite = async (password, code, client) => {
  const formData = new FormData()

  formData.append('password', password)
  formData.append('code', code)
  formData.append('client', client)

  const response = await axios.post(
    `/authentication/register/${code}/${client}/`,
    formData
  )

  return response
}

export const setTokens = tokens => {
  localStorage.setItem(AUTH_TOKEN_KEY, tokens[AUTH_TOKEN_KEY])
  localStorage.setItem(REFRESH_TOKEN_KEY, tokens[REFRESH_TOKEN_KEY])
}
export const setAuthToken = token => {
  localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export const setRefreshToken = token => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token)
}

// const getRefreshToken = () => {
//   return localStorage.getItem(REFRESH_TOKEN_KEY)
// }

const getAuthToken = () => {
  return localStorage.getItem(AUTH_TOKEN_KEY)
}

export const clearAuthTokens = () => {
  axios.defaults.headers.common['Authorization'] = ''
  localStorage.removeItem(VUEX_PERSIST_KEY)
  localStorage.removeItem(AUTH_TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}

export const isLoggedIn = () => {
  let authToken = getAuthToken()
  if (!!authToken && !isTokenExpired(authToken)) {
    return true
  } else {
    clearAuthTokens()
    return false
  }
}

export const getUserInfo = () => {
  if (isLoggedIn()) {
    return decode(getAuthToken())
  }
}

export const me = async () => {
  const response = await axios.get(`/users/me/`)

  return response
}

export const updateMe = async payload => {
  const response = await axios.patch('/users/me/', payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
}

const getTokenExpirationDate = encodedToken => {
  let token = decode(encodedToken)
  if (!token.exp) {
    return null
  }

  let date = new Date(0)
  date.setUTCSeconds(token.exp)

  return date
}

const isTokenExpired = token => {
  let expirationDate = getTokenExpirationDate(token)
  return expirationDate < new Date()
}
