import Vue from 'vue'
import _ from 'lodash'
import Toast from 'vue-toastification'
import { VueEditor } from 'vue2-editor'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import Vuelidate from 'vuelidate'
import moment from 'moment'
// const firebase = require('firebase/app').default
import 'firebase/messaging'
import VueApexCharts from 'vue-apexcharts'
import VTooltip from 'v-tooltip'
import Avatar from 'vue-avatar'
import { InlineSvgPlugin } from 'vue-inline-svg'

import 'vue-toastification/dist/index.css'
import 'vue2-datepicker/index.css'
import './assets/css/tailwind.css'
import './assets/css/icons.css'
import './assets/css/custom.css'

import 'vue2-datepicker/locale/de'

import VueHtmlToPaper from 'vue-html-to-paper'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import mixin from '@/utils/mixin'
// import titleMixin from '@/utils/titleMixin'
import axios from '@/utils/axios'

import 'vue-select/dist/vue-select.css'

Vue.use(InlineSvgPlugin)

const options = {
  timeout: 3000,
}

Vue.directive('away', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

Vue.prototype.placeholder = `<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path>`
Vue.use(VTooltip)
Vue.use(Toast, options)
Vue.use(Vuelidate)

Vue.use(VueApexCharts)
Vue.use(VueHtmlToPaper, {
  name: '_blank',
  specs: ['fullscreen=yes', 'scrollbars=yes'],
  styles: ['/style.css'],
})

Vue.component('apexchart', VueApexCharts)

Vue.prototype.moment = moment

Vue.mixin(mixin)
// Vue.mixin(titleMixin)

Vue.config.productionTip = false

Vue.prototype._ = _
Vue.prototype.axios = axios

Vue.prototype.$bus = new Vue({})

DatePicker.props.format.default = 'DD.MM.YYYY'
DatePicker.props.valueType.default = 'YYYY-MM-DD'

Vue.component('v-select', vSelect)
Vue.component('avatar', Avatar)
Vue.component('date-picker', DatePicker)

Vue.component('form-select', () => import('@/components/Inputs/FormSelect'))
Vue.component('strip-payment', () =>
  import('@/components/Commons/StripPayment')
)
Vue.component('vue-editor', VueEditor)
Vue.component('modal', () => import('@/components/Modals/Modal'))
Vue.component('notification', () => import('@/components/Commons/Notification'))
Vue.component('right-sidebar', () =>
  import('@/components/Sidebars/RightSidebar')
)
Vue.component('breadcrumbs', () => import('@/components/Header/Breadcrumbs'))

Vue.component('save-filter', () => import('@/components/Forms/Personal/Filter'))
Vue.component('data-table', () => import('@/components/DataTable'))
Vue.component('cgdata-table', () => import('@/components/Commons/CGDataTable'))
Vue.component('data-scroller', () =>
  import('@/components/DataTable/DataScroller')
)
Vue.component('data-repeater', () =>
  import('@/components/DataTable/DataRepeater')
)
Vue.component('tab-card', () => import('@/components/TabCard'))
Vue.component('repeater', () => import('@/components/Repeater'))

Vue.component('new-roleprofile', () =>
  import('@/components/Forms/Personal/Roleprofile/New')
)
Vue.component('new-goal', () => import('@/components/Forms/Personal/Goal/New'))

Vue.component('new-document-upload', () =>
  import('@/components/Forms/Personal/Document/New')
)

Vue.component('new-developmentaction', () =>
  import('@/components/Forms/Personal/Developmentaction/New')
)

Vue.component('form-edit', () => import('@/components/Inputs/FormEdit'))
Vue.component('remove-dialog', () => import('@/components/Dialogs/Remove'))

Vue.component('new-personal', () => import('@/components/Forms/Personal/New'))
Vue.component('new-role', () =>
  import('@/components/Forms/Organisation/Role/New')
)
Vue.component('new-employee', () =>
  import('@/components/Forms/Organisation/Employee/New')
)
Vue.component('new-requirement', () =>
  import('@/components/Forms/Organisation/Requirement/New')
)
Vue.component('new-task', () =>
  import('@/components/Forms/Organisation/Task/New')
)
Vue.component('new-skill', () =>
  import('@/components/Forms/Organisation/Skill/New')
)
Vue.component('new-competence', () =>
  import('@/components/Forms/Organisation/Competence/New')
)
Vue.component('new-obligation', () =>
  import('@/components/Forms/Organisation/Obligation/New')
)
Vue.component('new-document', () =>
  import('@/components/Forms/Organisation/Document/New')
)
Vue.component('new-input', () =>
  import('@/components/Forms/Organisation/Input/New')
)
Vue.component('new-interfac', () =>
  import('@/components/Forms/Organisation/Interfac/New')
)
Vue.component('new-output', () =>
  import('@/components/Forms/Organisation/Output/New')
)
Vue.component('new-rating-scale', () =>
  import('@/components/Forms/Organisation/Setting/RatingScale/New')
)
Vue.component('new-pdf-settings', () =>
  import('@/components/Forms/Organisation/Setting/PdfSettings/New')
)
Vue.component('new-notification-settings', () =>
  import('@/components/Forms/Organisation/Setting/NotificationSettings/New')
)
Vue.component('new-performance-review', () =>
  import('@/components/Forms/Employee/PerformanceReview/New')
)
Vue.component('new-performance-review-template', () =>
  import('@/components/Forms/Employee/PerformanceReview/Template/New')
)

Vue.component('goalevaluation', () =>
  import('@/components/Forms/Employee/PerformanceReview/Review/Goal')
)
Vue.component('rating-scales', () =>
  import(
    '@/components/Forms/Employee/PerformanceReview/Review/Custom/RatingScales'
  )
)
Vue.component('all-ratings', () =>
  import(
    '@/components/Forms/Employee/PerformanceReview/Review/Custom/AllRatings'
  )
)
Vue.component('goals', () =>
  import('@/components/Forms/Employee/PerformanceReview/Review/Custom/Goal')
)
Vue.component('personalityskillevaluation', () =>
  import(
    '@/components/Forms/Employee/PerformanceReview/Review/PersonalitySkill'
  )
)
Vue.component('taskevaluation', () =>
  import('@/components/Forms/Employee/PerformanceReview/Review/Task')
)
Vue.component('satisfactions', () =>
  import('@/components/Forms/Employee/PerformanceReview/Review/Satisfaction')
)
Vue.component('feedbacks', () =>
  import('@/components/Forms/Employee/PerformanceReview/Review/Feedback')
)
Vue.component('generaltopic', () =>
  import('@/components/Forms/Employee/PerformanceReview/Review/GeneralTopic')
)
Vue.component('developmentactions', () =>
  import(
    '@/components/Forms/Employee/PerformanceReview/Review/Custom/EvaluateDevelopmentActions'
  )
)
Vue.component('define_development_actions', () =>
  import(
    '@/components/Forms/Employee/PerformanceReview/Review/Custom/DefineDevelopmentActions'
  )
)

Vue.component('new-groups-and-permissions', () =>
  import('@/components/Forms/Organisation/GroupsAndPermissions/New')
)
Vue.component('date-filter', () => import('@/components/Commons/DateFilter'))
Vue.component('pdf-viewer', () => import('@/components/Commons/Pdf'))
Vue.component('toggle', () => import('@/components/Commons/Toggle'))

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
