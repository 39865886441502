<template>
  <div
    v-show="loader"
    class="fixed inset-0 z-50 flex items-center justify-center bg-gray-400 bg-opacity-75"
  >
    <div class="border w-32 rounded-lg relative overflow-hidden">
      <img src="@/assets/images/loading.svg" />
      <div class="text-white text-xs font-light text-center absolute bottom-1 w-full">
        Bitte warten...
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>

</style>
