/* eslint-disable no-async-promise-executor */
import _ from 'lodash'
import axios from '@/utils/axios'
import { xhr } from '@/utils/axios'

export const getAll = async (no_pagination, loading = true) => {
  let response = null

  if (loading) {
    if (no_pagination) {
      response = await axios.get(`/personal/?no_pagination=${no_pagination}`)
      return response
    }

    response = await axios.get(`/personal/`)
  } else {
    response = await xhr.get(`/personal/`)
  }

  return response
}

export const getList = async () => {
  const response = await axios.get(`/personal/list/`)

  return response
}

export const getOne = async id => {
  const response = await axios.get(`/personal/${id}/`)

  return response
}

export const createOne = async payload => {
  // const data = _.pickBy(payload, _.identity)
  const formData = new FormData()

  _.each(payload, (value, key) => {
    value && formData.append(key, value)
  })

  const response = await axios.post(`/personal/`, formData)

  return response
}

export const updateOne = async (id, payload) => {
  const formData = new FormData()

  _.each(_.omit(payload, ['user']), (value, key) => {
    if (key !== 'personal_avatar') {
      formData.append(key, value)
    } else if (key === 'personal_avatar' && value) {
      formData.append(key, value)
    }
  })

  const response = await axios.patch(`/personal/${id}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response
}

export const deleteOne = async id => {
  const response = await axios.delete(`/personal/${id}/`)

  return response
}

export const destroyOne = async ({ id }) => {
  const response = await axios.delete(`/users/?user_id=${id}`)

  return response
}

export const inviteOne = async ({ email }) => {
  const response = await axios.post(
    `/organisation/sendInvitation/`,
    {
      email: email,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response
}

export const downloadTemplate = async () => {
  axios.get(`/personal/download-template`).then(response => {
    window.open(response.data.fileURL)
  })
}

export const importPersonal = async payload => {
  const formData = new FormData()

  _.each(payload, (value, key) => {
    value.name && formData.append(key, value)
  })

  const response = await axios.post(`/personal/import/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response
}
