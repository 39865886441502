/* eslint-disable no-async-promise-executor */
import _ from "lodash"
import axios from "@/utils/axios"
import { xhr } from "@/utils/axios"

export const getAll = async (archived = false, loading = true, no_pagination = false) => {
  let response = null
  console.log('getAll', archived, loading, no_pagination)

  if(no_pagination){
    response = await axios.get(
      `/organisation/unit/roleprofile/?archived=${archived}&no_pagination=${no_pagination}`
    )
    return response
  }
  if (loading) {
    response = await axios.get(
      `/organisation/unit/roleprofile/?archived=${archived}`
    )
  } else {
    response = await xhr.get(
      `/organisation/unit/roleprofile/?archived=${archived}`
    )
  }

  return response
}

export const getOne = async (id, loading = true) => {
  let response = null

  if (loading) {
    response = await axios.get(`/organisation/unit/roleprofile/${id}/`)
  } else {
    response = await xhr.get(`/organisation/unit/roleprofile/${id}/`)
  }

  return response
}

export const createOne = async (payload) => {
  const formData = new FormData()

  _.each(payload, (value, key) => {
    value && formData.append(key, value)
  })

  const response = await axios.post(`/organisation/unit/roleprofile/`, formData)

  return response
}

export const updateOne = async (payload) => {
  const formData = new FormData()

  _.each(payload, (value, key) => {
    key !== "employes" && formData.append(key, value)
  })

  const response = await axios.patch(
    `/organisation/unit/roleprofile/${payload.id}/`,
    formData
  )

  return response
}

export const deleteOne = async ({ id }) => {
  const response = await axios.delete(`/organisation/unit/roleprofile/${id}/`)

  return response
}
